<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-fieldworks">
      Fieldwork Records
    </div>
    <div class="subhead-text pb-5">
      Manage students' fieldwork entries for the Mentor Externship Program.
    </div>
    <div class="grid gap-8 pb-5">
      <div class="box-style">
        <div id="admin-fieldwork-filters" class="secondhead-text">Filters</div>
        <div class="grid grid-cols-3">
          <div>
            <div id="admin-fieldwork-filters-status" class="pb-3">Status</div>
            <div>
              <ul class="grid grid-cols-2 gap-2 w-2/3">
                <li class="relative inline-block">
                  <input
                    class="sr-only peer"
                    type="radio"
                    name="status"
                    id="status-todo"
                    value="TODO"
                    v-model="status"
                  />
                  <label
                    class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    for="status-todo"
                    >SUBMITTED</label
                  >
                </li>
                <li class="relative inline-block">
                  <input
                    class="sr-only peer"
                    type="radio"
                    name="status"
                    id="status-approved"
                    value="APPROVED"
                    v-model="status"
                  />
                  <label
                    class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    for="status-approved"
                    >APPROVED</label
                  >
                </li>
                <li class="relative inline-block">
                  <input
                    class="sr-only peer"
                    type="radio"
                    name="status"
                    id="status-rejected"
                    value="REJECTED"
                    v-model="status"
                  />
                  <label
                    class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    for="status-rejected"
                    >REJECTED</label
                  >
                </li>
                <li class="relative inline-block">
                  <input
                    class="sr-only peer"
                    type="radio"
                    name="status"
                    id="status-returned"
                    value="RETURNED"
                    v-model="status"
                  />
                  <label
                    class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    for="status-returned"
                    >RETURNED</label
                  >
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="admin-fieldwork-filter-year" class="pb-3">Grade Level</div>
            <div>
              <ul class="grid grid-cols-4 gap-2 w-3/4">
                <li class="relative inline-block">
                  <input
                    class="sr-only peer"
                    type="radio"
                    name="at_level"
                    id="l1"
                    value="1L"
                    v-model="year"
                  />
                  <label
                    class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center w-12 h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    for="l1"
                    >1L</label
                  >
                </li>
                <li class="relative inline-block">
                  <input
                    class="sr-only peer"
                    type="radio"
                    name="at_level"
                    id="l2"
                    value="2L"
                    v-model="year"
                  />
                  <label
                    class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center w-12 h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    for="l2"
                    >2L</label
                  >
                </li>
                <li class="relative inline-block">
                  <input
                    class="sr-only peer"
                    type="radio"
                    name="at_level"
                    id="l3"
                    value="3L"
                    v-model="year"
                  />
                  <label
                    class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center w-12 h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    for="l3"
                    >3L</label
                  >
                </li>
                <li class="relative inline-block">
                  <input
                    class="sr-only peer"
                    type="radio"
                    name="at_level"
                    id="llm"
                    value="llm"
                    v-model="year"
                  />
                  <label
                    class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center w-12 h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    for="llm"
                    >LLM</label
                  >
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="admin-fieldwork-filters-type" class="pb-3">Type</div>
            <ul class="grid grid-cols-2 gap-2">
              <li class="relative inline-block">
                <input
                  class="sr-only peer"
                  type="radio"
                  name="type"
                  id="debrief"
                  value="Debrief"
                  v-model="type"
                />
                <label
                  class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                  for="debrief"
                  >DEBRIEF</label
                >
              </li>
              <li class="relative inline-block">
                <input
                  class="sr-only peer"
                  type="radio"
                  name="type"
                  id="experience"
                  value="Experience"
                  v-model="type"
                />
                <label
                  class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                  for="experience"
                  >EXPERIENCE</label
                >
              </li>
              <li class="relative inline-block">
                <input
                  class="sr-only peer"
                  type="radio"
                  name="type"
                  id="yem"
                  value="Year-End Meeting"
                  v-model="type"
                />
                <label
                  class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                  for="yem"
                  >YEAR-END MEETING</label
                >
              </li>
              <li class="relative inline-block">
                <input
                  class="sr-only peer"
                  type="radio"
                  name="type"
                  id="orientation"
                  value="Orientation"
                  v-model="type"
                />
                <label
                  class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                  for="orientation"
                  >ORIENTATION</label
                >
              </li>
              <li class="relative inline-block">
                <input
                  class="sr-only peer"
                  type="radio"
                  name="type"
                  id="networking"
                  value="Networking"
                  v-model="type"
                />
                <label
                  class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                  for="networking"
                  >NETWORKING</label
                >
              </li>
              <li class="relative inline-block">
                <input
                  class="sr-only peer"
                  type="radio"
                  name="type"
                  id="point-contact"
                  value="Point of Contact"
                  v-model="type"
                />
                <label
                  class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                  for="point-contact"
                  >POINT OF CONTACT</label
                >
              </li>
              <li class="relative inline-block">
                <input
                  class="sr-only peer"
                  type="radio"
                  name="type"
                  id="ppdp-meeting"
                  value="PPDP Meeting"
                  v-model="type"
                />
                <label
                  class="block border border-dark-purple rounded-full text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                  for="ppdp-meeting"
                  >PPDP MEETING</label
                >
              </li>
            </ul>
          </div>
          <div class="row-start-2 col-span-3 grid grid-cols-3 gap-3">
            <div>
              <label for="student-fn-filter" class="pb-3"
                >Student First Name</label
              >
              <input
                id="student-fn-filter"
                type="text"
                class="w-full rounded"
                v-model="student_first_name"
                placeholder="Filter by first or preferred names"
              />
            </div>
            <div>
              <label for="student-ln-filter" class="pb-3"
                >Student Last Name</label
              >
              <input
                id="student-ln-filter"
                type="text"
                class="w-full rounded"
                v-model="student_last_name"
                placeholder="Filter by last names"
              />
            </div>
          </div>
        </div>
        <div class="pt-3">
          <div class="relative h-14 border-t border-steel-gray">
            <div class="profile-box">
              <div class="flex justify-end py-3">
                <button
                  aria-label="Reset Filters"
                  @click="reset"
                  class="inline-block py-2 mr-4 px-4 bg-white border-purple border rounded-full text-dark-purple hover:text-white hover:bg-purple"
                >
                  Reset
                </button>
                <button
                  aria-label="Apply Filters"
                  @click="fetchAdminFieldworks"
                  class="inline-block py-2 mr-4 px-4 bg-white border-purple border rounded-full text-dark-purple hover:text-white hover:bg-purple"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-style overflow-auto">
        <div v-if="isLoading">
          <LoadingSpinner />
        </div>
        <div v-else>
          <div v-if="filteredFieldworks">
            <dynamic-table
              :records="this.filteredFieldworks"
              :fields="tableConfig"
              :pagination-page="paginationPage"
              :pagination-total-items="paginationTotalItems"
              :id-name="'fieldwork_id'"
              :from-route="'AdminFieldworks'"
              :row-path="'FieldworksView'"
              @pageChanged="nextPage($event)"
              no-data-msg="No fieldworks to display for the current filter"
              hover
              striped
              pagination
            >
              <template #cell(student-name)="{ item }">
                <div
                  @click="
                    $router.push(`/admin/fieldworks/${item.fieldwork_id}`)
                  "
                >
                  {{ item.student.firstname }}
                  {{ item.student.lastname }}
                </div>
              </template>
              <template #cell(description)="{ item }">
                <div
                  @click="
                    $router.push(`/admin/fieldworks/${item.fieldwork_id}`)
                  "
                >
                  <div v-if="item.fieldwork_type_name === 'Experience'">
                    {{ experience_lookup(item) }}
                  </div>
                </div>
              </template>
              <template #cell(fieldwork-academic-level)="{ item }">
                <div
                  @click="
                    $router.push(`/admin/fieldworks/${item.fieldwork_id}`)
                  "
                >
                  {{ item.acad_level.level }}
                </div>
              </template>
              <template #cell(fieldwork-last-updated)="{ item }">
                <div
                  @click="
                    $router.push(`/admin/fieldworks/${item.fieldwork_id}`)
                  "
                >
                  {{ TableDateFormat(item.added, true) }}
                </div>
              </template>
              <template #cell(fieldwork-comments)="{ item }">
                <div
                  @click="
                    $router.push(`/admin/fieldworks/${item.fieldwork_id}`)
                  "
                >
                  Foo
                  <!--                  <entry-messages latest in-table :_id="item.fieldwork_id" />-->
                </div>
              </template>
              <template #cell(fieldwork-status)="{ item }">
                <div
                  @click="
                    $router.push(`/admin/fieldworks/${item.fieldwork_id}`)
                  "
                >
                  <div class="my-1 w-1/2 px-0.5 mx-2">
                    <div
                      class="rounded-full text-center"
                      :class="{
                        'bg-purple': item.event_status === 'TODO',
                        'bg-alert-warn': item.event_status === 'RETURNED',
                        'bg-alert-success': item.event_status === 'APPROVED',
                        'bg-orange': item.event_status === 'REJECTED',
                        'bg-alert-danger': item.event_status === 'DELETED',
                      }"
                    >
                      <font-awesome-icon
                        :class="
                          item.event_status === 'RETURNED'
                            ? 'text-returned-text'
                            : 'text-white'
                        "
                        :icon="
                          item.event_status === 'APPROVED'
                            ? 'check'
                            : item.event_status === 'RETURNED'
                            ? 'reply'
                            : item.event_status === 'TODO'
                            ? 'hourglass'
                            : 'ban'
                        "
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(fieldwork-date)="{ item }">
                <div
                  @click="
                    $router.push(`/admin/fieldworks/${item.fieldwork_id}`)
                  "
                >
                  {{ TableDateFormat(item.fieldwork_date) }}
                </div>
              </template>
              <!--
              <template #cell(fieldwork-details)="{ item }">
                <button
                  aria-label="view-fieldwork-details-button"
                  :id="item.fieldwork_id"
                  class="w-6 h-6 text-dark-purple bg-white hover:bg-dark-purple hover:text-white border rounded"
                  @click="
                    $router.push({
                      name: 'FieldworksView',
                      params: {
                        _id: item.fieldwork_id,
                        fromRoute: 'AdminFieldworks',
                      },
                    })
                  "
                >
                  <font-awesome-icon icon="clipboard-list" />
                </button>
              </template>-->
              <template #cell(student-details)="{ item }">
                <button
                  aria-label="view-fieldwork-details-button"
                  :id="item.student.person_id"
                  class="w-6 h-6 text-dark-purple bg-white hover:bg-dark-purple hover:text-white border rounded"
                  @click="
                    $router.push(`/admin/fieldworks/${item.fieldwork_id}`)
                  "
                >
                  <font-awesome-icon icon="user-circle" />
                </button>
              </template>
            </dynamic-table>
          </div>
          <div v-else>
            <label>Please select desired filters and click apply.</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { FormatDate, TableDateFormat } from "@/composables/date_utils";
import { get } from "@/composables/httpUtil";
import { mapGetters, mapMutations } from "vuex";
// import EntryMessages from "@/components/forms/EntryMessages";

export default {
  name: "FieldworksRecords",
  components: {
    // EntryMessages,
    DynamicTable,
    LoadingSpinner,
  },
  data() {
    return {
      fieldworks: [],
      filteredFieldworks: undefined,
      filterText: "",
      isLoading: false,

      status: "TODO",
      year: "",
      type: "",
      student_first_name: "",
      student_last_name: "",

      paginationTotalItems: 0,
      paginationTotalPages: 0,
      itemsPerPage: 25,
      paginationPage: 1,

      rowFunctionObject: {
        method(router, target) {
          router.push({
            name: "AdminStudentsDetail",
            params: { id: target.person_id },
          });
        },
        target: "student",
      },

      tableConfig: [
        {
          display: "Student Name",
          name: "student-name",
          sortable: false,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Type",
          name: "fieldwork_type_name",
          sortable: true,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Description",
          name: "description",
          sortable: true,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Academic Level",
          name: "fieldwork-academic-level",
          sortable: false,
          class: "hover:cursor-pointer text-center",
        },
        {
          display: "Date of Fieldwork",
          name: "fieldwork-date",
          sortable: true,
          sortType: "DATE",
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Date Submitted",
          name: "fieldwork-last-updated",
          sortable: true,
          sortType: "DATE",
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Status",
          name: "fieldwork-status",
          sortable: false,
          class: "hover:cursor-pointer text-left",
        },
        {
          display: "Student Details",
          name: "student-details",
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  created: function () {
    let filters = this.get_admin_fieldwork_records_filters;
    this.status = filters.status;
    this.year = filters.year;
    this.type = filters.type;
    this.student_first_name = filters.first_name;
    this.student_last_name = filters.last_name;
    this.fetchAdminFieldworks();
    // console.log("TYPES", this.lookup_fieldwork_types);
    // register watchers after data loaded to ensure a second call to the backend is not triggered
    this.$watch(
      (vm) => [
        vm.student_first_name,
        vm.student_last_name,
        vm.status,
        vm.year,
        vm.type,
      ],
      () => {
        this.paginationPage = 1;
        this.fetchAdminFieldworks();
        this.set_admin_fieldwork_records_filters({
          first_name: this.student_first_name,
          last_name: this.student_last_name,
          status: this.status,
          year: this.year,
          type: this.type,
        });
      }
    );
  },
  methods: {
    nextPage: function (paginationInformation) {
      this.paginationPage = paginationInformation.newPage;
      this.fetchAdminFieldworks();
    },
    FormatDate,
    TableDateFormat,
    experience_lookup: function (item) {
      let exp_desc = "";
      const lookup = this.lookup_fieldwork_types.find(
        (l) => l.fieldwork_log_type_id === item.fieldwork_type_id
      );
      item.fields.forEach((field) => {
        let value = lookup.fields.find(
          (l) => field.fieldwork_log_field_id === l.field_id
        );
        if (value.name === "Experience Type") {
          exp_desc = field.value;
        }
      });
      return exp_desc;
    },
    reset: function () {
      this.status = "";
      this.year = "";
      this.type = "";
      this.student_first_name = "";
      this.student_last_name = "";
      this.isLoading = false;
      this.set_admin_fieldwork_records_filters({
        level: undefined,
        type: undefined,
        status: undefined,
        first_name: undefined,
        last_name: undefined,
      });
    },
    fetchAdminFieldworks: function () {
      let url = `admin/fieldworks?page=${this.paginationPage}&limit=${this.itemsPerPage}&term=${this.lookup_current_term.term_id}`;
      url += this.status !== "" ? `&status=${this.status}` : "";
      url += this.year !== "" ? `&academic_level=${this.year}` : "";
      url += this.type !== "" ? `&fieldwork_type=${this.type}` : "";
      url +=
        this.student_first_name !== ""
          ? `&first_name=${this.student_first_name}`
          : "";
      url +=
        this.student_last_name !== ""
          ? `&last_name=${this.student_last_name}`
          : "";
      this.isLoading = true;
      get(url)
        .then((response) => {
          this.fieldworks = response.data.map((fw) => {
            const entry_type = this.lookup_fieldwork_types.find(
              (type) => type.fieldwork_log_type_id === fw.fieldwork_type_id
            );
            fw.entry_type_name = entry_type?.fieldwork_type_name;
            return fw;
          });
          this.filteredFieldworks = this.fieldworks;
          this.paginationTotalItems = response.count;
          this.paginationTotalPages = response.total_pages;
          this.isLoading = false;
        })
        .then(() => {
          this.$store.commit("set_entries", this.fieldworks);
        })
        .catch((error) => {
          console.log("error (fetchAdminFieldworks):", error);
          this.isLoading = false;
        });
    },
    ...mapMutations(["set_admin_fieldwork_records_filters"]),
  },
  computed: {
    ...mapGetters([
      "lookup_fieldwork_types",
      "get_admin_fieldwork_records_filters",
      "lookup_current_term",
    ]),
  },
};
</script>

<style scoped></style>
